.canvas {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.iframe {
  height: 0;
  display: none;
}
